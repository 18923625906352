import React from 'react'
import Lottie from "lottie-react";
import lottieanimation from './jumpropelottie.json'
import { Link } from 'react-router-dom';

function Error404() {

    // const options = {
    //     animationData: groovyWalkAnimation,
    //     loop: true
    // };

  return (
    <section className="bg-white dark:bg-gray-900">
        <div className="px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <Lottie 
                    animationData={lottieanimation} 
                    loop={true} 
                />
                <h1 className="mb-4 text-7xl text-fuchsia-400 tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
                <p className="mb-4 text-fuchsia-300 text-2xl tracking-tight md:font-bold md:text-4xl dark:text-white">Page not Found</p>
                {/* <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                <a href="#" className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</a> */}
                <Link to={"/"} >
                <button type="submit" className="my-8 transition duration-500 ease hover:-translate-y-1 inline-block bg-fuchsia-600 text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer md:w-1/2 w-full">
                    Go Home
                </button>
                </Link>
            </div>   
        </div>
    </section>
  )
}

export default Error404