import { collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '../firebase.config'
import { useParams } from 'react-router-dom'
import { fetchBlogs } from '../store/blogs.slice'
import Blog from './Blog'
import Recents from './Recents'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify';
import { toastprops } from '../constants/constants'
import { updateUser } from '../store/user.slice'
import BeatLoader from "react-spinners/BeatLoader";

function Profilesec() {

    const [showtextfield, setshowtextfield] = useState(false)
    const [guest, setguest] = useState(true)
    const [dbuser, setdbuser] = useState([])
    const [blogs, setblogs] = useState([])
    const [loading, setloading] = useState(false)

    const user = useSelector(state => state.user)    
    const {userid} = useParams() 
    const dispatch = useDispatch()

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();

    const fetchBlogPosts = async() => {
        let q
        if(!guest) {
            q = query(collection(db, "blogposts"), 
            where("deleted", "==", false),
            // where("reviewed", "==", true), 
            where("userid", "==", userid),
            orderBy("timestamp", "desc"),
            );
        } else {
            q = query(collection(db, "blogposts"), 
            where("deleted", "==", false),
            where("reviewed", "==", true), 
            where("userid", "==", userid),
            orderBy("timestamp", "desc"),
            );
        }
        const data = await getDocs(q)  
        // const data = await getDocs(blogsCollectionRef)        
        const blogposts = data.docs.map(doc => ({
            ...doc.data(), 
            id: doc.id
        }))
        setblogs(blogposts)
        // dispatch(fetchBlogs(blogposts))               
    }    

    const getuser = async() => {
        try{
            const dbuser = await getDoc(doc(db, "users", userid))
            setdbuser(dbuser?.data())
        } catch(error) {
            console.log("error>>", error)
        }        
    }

    const isvisitor = () => {
        userid.trim()==user?.uid?.trim() ? setguest(false) : setguest(true)
    }

    const updateinsta = async (data) => {
        try {
            setloading(true)
            const {instagramurl} = data
            // const matches = data["instagramurl"].match(/(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_\.]+)/im)
            // const instaname = matches[1]
            const instaname = instagramurl.trim()
            const url = `https://www.instagram.com/${instaname}/`
            const userref = doc(db, "users", userid)
            await updateDoc(userref, {
                instagramurl: url, 
                instaname: instaname
            })
            setloading(false)
            toast.success('success', toastprops);
            // dispatch(updateUser({
            //     instagramurl: instagramurl, 
            //     instaname: instaname
            // }))
            setdbuser({...dbuser, 
                instagramurl: url, 
                instaname: instaname
            })
            setshowtextfield(false)
        } catch (error) {
            console.log("error>>", error);
            setloading(false)
            toast.error(error.code, toastprops);
        }
    }    

    const deletePost = async(id) => {
        try {
            const docref = doc(db, "blogposts", id)
            await updateDoc(docref, {deleted: true})
            const newblogs = blogs.filter(blog => blog.id!==id)
            setblogs(newblogs)
            // alert("deleted")
            // togglemodal(false)
            toast.success("deleted successfully", toastprops)
        } catch (error) {
            // togglemodal(false)
            toast.error(error.code, toastprops)
            console.log("error>>", error)
        }    
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        getuser()
        isvisitor()
        // fetchBlogPosts()
    }, [userid])
    
    useEffect(() => {
        fetchBlogPosts()
    }, [guest])

  return (
    <div className="md:p-16 py-12">
        <div className="md:p-8 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-1">
            {/* <div className="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0">
                <div>
                    <p className="font-bold text-gray-600 text-xl">22</p>
                    <p className="text-gray-400">Friends</p>
                </div>
                <div>
                    <p className="font-bold text-gray-600 text-xl">10</p>
                    <p className="text-gray-400">Photos</p>
                </div>
                    <div>
                    <p className="font-bold text-gray-600 text-xl">89</p>
                    <p className="text-gray-400">Comments</p>
                </div>
            </div> */}
            <div className="">
                <div className="w-48 h-48 bg-fuchsia-100 mx-auto rounded-full shadow-2xl inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg> */}
                <i className="fa-solid fa-user text-8xl text-fuchsia-600"></i>
                </div>
            </div>

            {/* <div className="space-x-8 flex justify-between mt-32 md:mt-0 md:justify-center">
                <button
                className="text-white py-2 px-4 uppercase rounded bg-blue-400 hover:bg-blue-500 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                >
                Connect
                </button>
                    <button
                className="text-white py-2 px-4 uppercase rounded bg-gray-600 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                >
                Message
                </button>
            </div> */}
        </div>

        <div className="mt-20 flex flex-col items-center text-center border-b pb-12 transition duration-500 ease">
            <h1 className="md:text-4xl text-4xl font-medium text-gray-600">
                {dbuser?.username} 
            {/* <span className="font-light text-gray-500">27</span> */}
            </h1>
            {/* <p className="font-light text-gray-600 mt-2">{dbuser?.email}</p> */}

            
            {/* <p className="mt-2 text-gray-500">University of Computer Science</p> */}
          
            {
                dbuser?.instagramurl && !showtextfield ?
                <p className="mt-4 flex items-center text-pink-700 cursor-pointer">
                    <i className="fa-brands fa-instagram text-2xl mx-2"></i>
                    <a className='hover:underline' target="_blank" href={dbuser?.instagramurl} >{dbuser?.instaname}</a>
                    {/* <i className="fa-solid fa-up-right-from-square text-xs mx-2"></i> */}
                    { !guest && <i className="fa-solid fa-pen-to-square mx-4 text-gray-500" onClick={() => setshowtextfield(true)} ></i> }
                </p> 
                :            
                showtextfield && !guest ?
                <form className="w-full max-w-sm items-center mt-6" onSubmit={handleSubmit(updateinsta)} >
                    <div className="flex items-center border-b border-fuchsia-600 py-2">
                        <input {...register('instagramurl', {
                                required: {
                                    value: true, 
                                    message: "Please enter username"
                                }, 
                                //     pattern: {
                                //     value: /(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_\.]+)/im,
                                //     message: "Please enter valid instagram url"
                                // } 
                            })} 
                                defaultValue={dbuser?.instagramurl ? dbuser?.instagramurl : ""}
                                name="instagramurl" id="instagramurl" className="appearance-none bg-transparent border-none w-full text-gray-600 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Enter Instagram username" aria-label="instagramurl" />
                        <button className="flex-shrink-0 bg-fuchsia-600 border-fuchsia-600 transition duration-500 ease hover:-translate-y-1 text-sm border-4 text-white py-1 px-2 rounded" type="submit">
                        {
                            loading ? 
                            <BeatLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    // cssOverride={override}
                                    size={4}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                            /> : "Add"
                        }                        
                        </button>
                        <button onClick={() => {setshowtextfield(false)}} className="flex-shrink-0 transition duration-500 ease hover:-translate-y-1 border-transparent border-4 text-fuchsia-500 hover:fuchsia-600 text-sm py-1 px-2 rounded" type="button">
                        <i className="fa-solid fa-xmark text-lg"></i>
                        </button>                        
                    </div>
                    {errors["instagramurl"] && <p className="text-xs text-red-500">{errors["instagramurl"]?.message}</p>}
                </form>
                : !guest && <p className="mt-6 text-gray-500 cursor-pointer hover:text-fuchsia-600" onClick={() => {setshowtextfield(true)}}><i className="fa-solid fa-user-plus mx-2"></i>Add Instagram username</p>
            }
            
        </div>

        <div className="mt-6 flex flex-col justify-center">
            {/* <p className="text-gray-600 text-center font-light lg:px-16">
                An artist of considerable range, Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, 
                performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. 
                An artist of considerable range.
            </p>
            <button
            className="text-indigo-500 py-2 px-4  font-medium mt-4"
            >
            Show more
            </button> */}
            <Recents title= {"My Posts"} profile= {true} guest= {guest} blogs={blogs} userid={userid} deletePost={(id) => deletePost(id)} />
        </div>
        
        </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />
        
        
    </div>
  )
}

export default Profilesec