import React, { useEffect } from 'react'

function Aboutus() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    //add keyframes and animations to this page in future to make it cool
    <div className='lg:col-span-12 col-span-1 flex flex-col items-center justify-center my-12 lg:my-0 h-screen' >
      <span className=' text-5xl mb-12  space-y-5 p-3 text-white border-spacing-0 border-white bg-gradient-to-t from-fuchsia-600 to-white ' >
        <i className="fa-solid fa-quote-left"></i>
        <span className=' font-semibold block tracking-widest ' >OUR MISSION</span>
      </span>
      <span className='text-3xl text-center whitespace-pre-wrap md:leading-loose leading-normal' >
        <span >To embrace a healthier lifestyle for everyone through <span className=' text-white p-3 font-bold tracking-widest bg-gradient-to-t from-fuchsia-600 ' >jump rope</span> {"\n"} - a symbol of active living and discipline.</span>
      </span>
      <i className="fa-solid fa-quote-right text-5xl text-fuchsia-600"></i>

      {/* <span className=' text-5xl mb-12  space-y-5 p-3 text-white border-spacing-0 border-white bg-gradient-to-t from-fuchsia-600 to-white ' >
        <i className="fa-solid fa-quote-left"></i>
        <span className=' font-semibold block tracking-widest ' >OUR VISION</span>
      </span>
      <span className='text-3xl text-center whitespace-pre-wrap md:leading-loose leading-normal' >
        <span >Everyone must own a <span className=' text-white p-3 font-bold tracking-widest bg-gradient-to-t from-fuchsia-600 ' >jump rope</span></span>
      </span>
      <i className="fa-solid fa-quote-right text-5xl text-fuchsia-600"></i> */}
    </div>
  )
}

export default Aboutus