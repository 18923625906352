import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import logotext from '../assets/images/logotext.png'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../firebase.config'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, updateUser } from '../store/user.slice'
import Avatar from './Avatar'
import { doc, getDoc } from 'firebase/firestore'

function Header() {

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  
  useEffect(() => {
    onAuthStateChanged(auth, async currentUser => {
      try {
        // const dbuserdoc = await getDoc(doc(db, "users", auth?.currentUser?.uid))
        // const dbuser = dbuserdoc.data()
        // const newcurrentUser = {...currentUser, ...dbuser}
        dispatch(fetchUser(currentUser))
      } catch (error) {
        console.log("error>>", error)
      }    
    })
  }, [])
  
  return (
    <div className="App container mx-auto px-10 mb-8">
        <div className="border-b w-full flex justify-between align-middle border-fuchsia-400 py-8">
            <div className="md:float-left block">
              <Link to="/">
                  <img src ={logotext} className='h-10 lg:h-16'/>
                  {/* <span className="cursor-pointer font-bold text-4xl text-fuchsia-600 flex items-center"><img src={logo} style={{width: '50px'}} />RopesOut</span> */}
              </Link>
            </div>
            <div className="flex items-center cursor-pointer hover:text-fuchsia-700" id='avatar'>
              {
                user ? 
                <Avatar /> :
                <Link to={"/login"}>
                  <button type="button" className="transition duration-500 ease 
                    /hover:bg-white /hover:text-fuchsia-600 /hover:border-fuchsia-600 /hover:border-2
                    hover:-translate-y-1
                    inline-block bg-fuchsia-600 rounded-full text-white md:px-5 md:py-2 px-3 py-2 text-xs md:text-base cursor-pointer">
                    sign in
                  </button>
                </Link>
              }                            
            {/* {categories.map((category, index) => (
                <Link key={index} href={`/category/${category.slug}`}><span className="md:float-right mt-2 align-middle text-white ml-4 font-semibold cursor-pointer">{category.name}</span></Link>
            ))} */}
            </div>
        </div>
    </div>

  )
}

export default Header