import React from 'react'
import logotext from '../assets/images/logotext.png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="w-full bg-white p-8">
        <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
            {/* <h1 className='text-fuchsia-400' >RopesOut</h1> */}
            <img src ={logotext} className='h-8'/>            
            <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
            <li>
                <Link
                to="/pages/about-us"
                className="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-fuchsia-600 focus:text-fuchsia-600"
                >
                About Us
                </Link>
            </li>
            <li>
                <a
                href="https://www.privacypolicygenerator.info/live.php?token=1ibS3tBKLUBoG6LBnzmopsYQVO85EZYZ"
                target="_blank"
                className="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
                >
                Privacy
                </a>
            </li>
            <li>
                <a
                href="https://www.termsandcondiitionssample.com/live.php?token=ceQLlEecvsC0ABesBqNRZKok35gHyKTR"
                target="_blank"
                className="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
                >
                Terms Of Use
                </a>
            </li>
            {/* <li>
                <a
                href="#"
                className="block font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-pink-500 focus:text-pink-500"
                >
                Contact Us
                </a>
            </li> */}
            </ul>
        </div>
        <hr className="my-2 border-blue-gray-50" />
        <p className="mt-4 block text-center font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
            © 2023 RopesOut
        </p>
    </footer>
  )
}

export default Footer