import React, { useEffect } from 'react'
import Blogs from '../../components/Blogs'
import Recents from '../../components/Recents'
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

function Home() {

  const blogs = useSelector(state => state.blogs)

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //   }, []);

  return (    
    <>
      <div className="lg:col-span-8 col-span-1">
        <Helmet>
          <title>RopesOut</title>
          <meta name='description' content='A website to promote jumprope as an effective healthy lifestyle choice' />
          <link rel='canonical' href='/' />
        </Helmet>
        <Blogs /> 
      </div>
      <Recents title={"Recent Posts"} limit={3} blogs={blogs}  />
    </>
  )
}

export default Home