import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { db } from '../firebase.config'
import { doc, updateDoc } from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify'
import { toastprops } from '../constants/constants'
import Deletemodal from './Deletemodal'

function Recents({title, profile, limit, guest, blogs, userid, deletePost}) {

  const [showmodal, setshowmodal] = useState(false)

  // const blogs = useSelector(state => state.blogs)

  const togglemodal = (value) => {
    setshowmodal(value)
  }

  // const deletePost = async(id) => {
  //   try {
  //     const docref = doc(db, "blogposts", id)
  //     await updateDoc(docref, {deleted: true})
  //     // alert("deleted")
  //     togglemodal(false)
  //     toast.success("deleted successfully", toastprops)
  //   } catch (error) {
  //     togglemodal(false)
  //     toast.error(error.code, toastprops)
  //     console.log("error>>", error)
  //   }    
  // }

  return (
    <>
    <div className="lg:col-span-4 col-span-1">
      <div className="lg:sticky relative top-8">
        <div className="bg-white rounded-lg py-8 md:px-4 pb-12 mb-8"> 
        {/* shadow-lg  */}
            <h3 className="md:text-xl text-lg mb-4 font-semibold border-b border-t p-4">
              { blogs?.length>0 ?
                title
                :"No blogs created."
              }
            </h3>
                               
            {blogs?.map((source, index) => index<(limit ? limit : blogs?.length) && (
                <div key={index} className="flex flex-col md:flex-row items-center w-full mb-4 pb-1 border-b">
                  <div className='flex items-center w-full' >
                  <Link to={`/post/${source?.id}`} className='flex items-center w-full' key="1">                    
                    <div className="w-16 flex-none">
                      {
                      source.image &&
                          <img
                          alt={source.title}
                          height="60px"
                          width="60px"
                          className="align-middle rounded-full"
                          src={source.image}
                          />
                      }
                    </div>
                    <div className="flex-grow ml-4">
                        <p className="text-gray-500 font-xs">{source.createdat}</p>
                        <p className='text-md line-clamp-2' >{source.title}</p>
                    </div>
                  </Link>
                  </div>
                  {
                    profile && !guest &&
                    <div className='flex mt-4 md:mt-0' >
                      {
                        source.reviewed ?
                          <span className='ml-4 pl-4 border-l border-slate-300 text-green-500 ' >
                          approved
                        </span>
                        : <span className='ml-4 pl-4 border-l border-slate-300 text-yellow-500 ' >
                          pending..
                        </span>
                      }                      
                    <i onClick={() => togglemodal(true)} className="fa-regular fa-trash-can pl-4 ml-4 cursor-pointer text-xl hover:text-fuchsia-700 border-l border-slate-300"></i>
                    </div>
                  }
                  <Deletemodal 
                    show={showmodal}
                    deletefunc = { (id) => {deletePost(id); togglemodal(false)}}
                    id={source.id}
                    togglemodal={(value) => togglemodal(value)}
                  />
                </div>
                
            ))}
            {
              profile && !guest &&
              <Link to={`/${userid}/pages/create-blog`}>
              <button type="button" className="my-8 transition duration-500 ease hover:-translate-y-1 inline-block bg-fuchsia-600 md:text-lg text-sm font-medium rounded-full text-white md:px-8 px-4 py-3 cursor-pointer w-full">
              <i className="fa-solid fa-plus"></i> Add post
              </button>
              </Link>
            } 
        </div>
      </div>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
              />
    </div>

</>
  )
}

export default Recents