import React from 'react'
import Profilesec from '../../components/Profile'

function Profile() {
  return (
    <div className='lg:col-span-12 col-span-1 flex flex-col items-center justify-center my-12 lg:my-0' >
        <Profilesec />
    </div>
  )
}

export default Profile