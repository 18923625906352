import { useNavigate, Navigate } from "react-router-dom";

export const Notforguests = ({ isLoggedIn, children }) => {
    // const navigate = useNavigate()
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }
  return children;
};

export const Notforusers = ({ isLoggedIn, children }) => {
    // const navigate = useNavigate()
    if (isLoggedIn) {
      return <Navigate to="/" />;
    }
    return children;
  };
