import React, { useEffect } from 'react'
import BlogDetail from '../../components/BlogDetail'
import Recents from '../../components/Recents'
import { useSelector } from 'react-redux'

function Blogpage() {

  const blogs = useSelector(state => state.blogs)
    
  return (
    <>
      <div className="lg:col-span-8 col-span-1">
        <BlogDetail />    
      </div>
      <Recents title={"Recent Posts"} limit={3} blogs={blogs} />
    </>
  )
}

export default Blogpage