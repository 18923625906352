import { createSlice } from "@reduxjs/toolkit"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../firebase.config"

const initialState = []

const blogsSlice = createSlice({
    name: "blogs", 
    initialState, 
    reducers: {
        fetchBlogs(state, action){
            return action.payload
            // return [...state, ...action.payload]
            // state.push(action.payload)
        }
    }
})

export const {fetchBlogs} = blogsSlice.actions;
export default blogsSlice.reducer;