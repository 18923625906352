import React, { useEffect } from 'react'
import CreatePost from '../../components/CreatePost'

function CreateBlog() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <CreatePost />
  )
}

export default CreateBlog