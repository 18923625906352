import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logotext from '../assets/images/logotext.png'
import { useForm } from 'react-hook-form';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase.config';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../store/user.slice';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import BeatLoader from "react-spinners/BeatLoader";

function Signup({flip}) {

    const [signupstatus, setsignupstatus] = useState({
        loading: false, 
        error: null,
    })
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();

    const registeruser = async (data) => {
        try {
            setsignupstatus({...signupstatus, loading: true, error: null})
            const user = await createUserWithEmailAndPassword(auth, data.email, data.password)
            const dbuser = {
                // userid: user?.user?.uid, 
                username: data.username, 
                email: data.email, 
                instagramurl: null,
                instaname: null,
            }
            // const userref = collection(db, "users")
            const userdocref = doc(db, "users", `${user?.user?.uid}`)
            await setDoc(userdocref, dbuser)
            // await setDoc(doc(db, userref, dbuser.userid), dbuser)
            // dispatch(fetchUser())
            setsignupstatus({...signupstatus, loading: false, error: null})
            return navigate("/");
        } catch (error) {
            console.log("error>>", error)
            setsignupstatus({...signupstatus, loading: false, error: error.code})
        }
    }
  return (
    <section className="bg-gray-50 dark:bg-gray-900 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
            {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
                Flowbite    
            </a> */}
            <Link to="/">
                <img src ={logotext} className='h-12 lg:h-16 mb-6'/>
            </Link>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Create account
                    </h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(registeruser)}>
                        <div>
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">username</label>
                            <input {...register('username', {
                                required: {
                                    value: true, 
                                    message: "Please enter username"
                                }, 
                                })} 
                                type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="username" required="" />
                                {errors?.username && <p className="text-xs text-red-500">{errors?.username?.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">email</label>
                            <input {...register('email', {
                                required: {
                                    value: true, 
                                    message: "Please enter email"
                                }, 
                                    pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Please enter valid email"
                                } })} 
                                type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                                {errors?.email && <p className="text-xs text-red-500">{errors?.email?.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input {...register("password", {
                                required: {
                                    value: true, 
                                    message: "Please enter password"
                                },
                                minLength: {
                                    value: 6,
                                    message: "password must contain atleast 6 characters"
                                }
                            })} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            {errors?.password && <p className="text-xs text-red-500">{errors?.password?.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                            <input {...register("confirm-password", {
                                // required: {
                                //     value: true, 
                                //     message: "Please enter password"
                                // },
                                validate: (val) => {
                                    if (watch('password') != val) {
                                    return "Your passwords do no match";
                                    }
                                },
                            })} 
                            type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            {errors["confirm-password"] && <p className="text-xs text-red-500">{errors["confirm-password"].message}</p>}
                        </div>
                        {/* <div className="flex items-start">
                            <div className="flex items-center h-5">
                                <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                            </div>
                        </div> */}
                        <button type="submit" className="w-full text-white bg-fuchsia-600 transition duration-500 ease hover:-translate-y-1 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        {
                            signupstatus.loading ? 
                                <BeatLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    // cssOverride={override}
                                    size={6}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> :  
                                "Create an account"
                        } 
                        </button>
                        {signupstatus?.error && <p className="text-xs text-red-500">{signupstatus?.error}</p>}
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Already have an account? <span href="#" className="font-medium text-primary-600 hover:underline hover:text-fuchsia-600 dark:text-primary-500 cursor-pointer" onClick={() => flip(true)} >Login here</span>
                        </p>
                    </form>
                    <p className=' text-[0.7rem]' >{"By signing up, I agree to Ropeout's "} 
                    <a
                        href="https://www.termsandcondiitionssample.com/live.php?token=ceQLlEecvsC0ABesBqNRZKok35gHyKTR"
                        target="_blank"
                        className='underline'
                    >
                        {"Terms of Use"}
                    </a>
                     {" and "} 
                    <a
                        href="https://www.privacypolicygenerator.info/live.php?token=1ibS3tBKLUBoG6LBnzmopsYQVO85EZYZ"
                        target="_blank"
                        className='underline'
                    >
                        {"Privacy Policy"}
                    </a>
                    </p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Signup