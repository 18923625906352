import React, { useEffect, useState } from 'react'
import { storage, db } from '../firebase.config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { collection, addDoc, getDoc, doc, Timestamp, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { ToastContainer, toast } from 'react-toastify';
import BeatLoader from "react-spinners/BeatLoader";
import { toastprops } from '../constants/constants';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

function CreatePost() {

    const [dbuser, setdbuser] = useState([])

    const user = useSelector(state => state.user)    
    const [poststatus, setpoststatus] = useState({
        loading: false, 
        error: null,
    })
    const {userid} = useParams() 

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();  
    
    const getuser = async() => {
        try{
            const dbuser = await getDoc(doc(db, "users", userid))
            setdbuser(dbuser?.data())
        } catch(error) {
            console.log("error>>", error)
        }        
    }

    const handlePostSubmission = async (data) => {
        setpoststatus({...poststatus, loading: true, error: null})
        const { title, content, coverphoto } = data;        
        const image = coverphoto[0]
        let newpost = {
            userid: user?.uid,
            author: dbuser?.username,
            title: title, 
            post: content, 
            image: null, 
            reviewed: false, 
            deleted: false, 
            createdat: moment().format("ll"),
            timestamp: serverTimestamp(),
        }        
        try{
            if(image) {
                const imageRef = ref(storage, `images/${image?.name + uuidv4()}`);
                await uploadBytes(imageRef, image)
                .then(async snapshot => {
                    await getDownloadURL(snapshot.ref).then(url => {
                        newpost = {...newpost, image: url}
                    })
                })
            }            
            const docref = await addDoc(collection(db, "blogposts"), newpost)
            setpoststatus({...poststatus, loading: false, error: null})
            toast.success('post submitted', toastprops);
        } catch(error) {
            setpoststatus({...poststatus, loading: false, error: error.code})
            toast.error(error.code, toastprops);
            console.log("error>>", error)
        }        
    };

    useEffect(() => {
        getuser()
    }, [userid])

    return (
        <div className='lg:col-span-12 col-span-1 items-center justify-center my-12 lg:my-0 h-screen' >
            <form className="bg-white py-8 md:px-8 pb-12 mb-8" onSubmit={handleSubmit(handlePostSubmission)} >
                <h3 className="text-xl mb-8 font-semibold border-b pb-4">Create New Blog Post</h3>
                <div className='grid grid-cols-1 gap-4 mb-4' >
                    <input type="text" 
                        {...register('title', {
                        required: {
                            value: true, 
                            message: "Title and Content are mandatory"
                        }, 
                        })}
                    // value={formData.title} onChange={onInputChange} 
                    className="py-2 px-4 outline-none w-full rounded-lg focus:ring-2 focus:ring-gray-200 bg-gray-100 text-gray-700" placeholder="Title*" name="title" />
                </div>
                <div className="grid grid-cols-1 gap-4 mb-4">
                    <textarea 
                    {...register('content', {
                        required: {
                            value: true, 
                            message: "Title and Content are mandatory"
                        }, 
                    })}
                    // value={formData.content} onChange={onInputChange} 
                    className="p-4 outline-none w-full rounded-lg h-40 focus:ring-2 focus:ring-gray-200 bg-gray-100 text-gray-700" name="content" placeholder="Content*" />
                </div>
                {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                    <input type="text" 
                    // value={formData.name} onChange={onInputChange} 
                    className="py-2 px-4 outline-none w-full rounded-lg focus:ring-2 focus:ring-gray-200 bg-gray-100 text-gray-700" placeholder="Name" name="name" />
                    <input type="email" 
                    // value={formData.email} onChange={onInputChange} 
                    className="py-2 px-4 outline-none w-full rounded-lg focus:ring-2 focus:ring-gray-200 bg-gray-100 text-gray-700" placeholder="Email" name="email" />
                </div> */}
                <div className="grid grid-cols-1 gap-4 mb-4">
                    <label
                    htmlFor="coverphoto"
                    className="inline-block text-neutral-700 dark:text-neutral-200"
                    >Please select a cover photo (optional)</label>
                    <input className="block w-full cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] font-normal leading-[2.15] text-gray-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-gray-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    {...register('coverphoto')}
                    // onChange={onInputChange}
                    // value={formData.coverphoto}
                    name='coverphoto'
                    id="coverphoto"
                    type="file"
                    accept="image/png, image/gif, image/jpeg" />
                    {/* <div>
                    <input 
                    //   checked={formData.storeData} onChange={onInputChange} 
                    type="checkbox" id="storeData" name="storeData" value="true" />
                    <label className="text-gray-500 cursor-pointer" htmlFor="storeData"> Save my name, email in this browser for the next time I comment.</label>
                    </div> */}
                </div>
                {/* {error && <p className="text-xs text-red-500">Title and Content are mandatory fields</p>} */}
                <div className="mt-8">
                    <button type="submit" 
                    // onClick={handlePostSubmission} 
                    className="transition duration-500 ease hover:-translate-y-1 inline-block bg-fuchsia-600 text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer md:w-1/2 w-full">
                        {
                            poststatus.loading ? 
                                <BeatLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    // cssOverride={override}
                                    size={6}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> :  
                                "Post Blog"
                        } 
                    </button>
                </div>
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <p className='hidden' >{(errors?.title || errors?.content) && toast.error(errors?.title?.message,toastprops )}</p>
        </div>
    )
}

export default CreatePost