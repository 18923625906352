import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';

function BlogDetail() {
    const [blog, setBlog] = useState({})
    const {id} = useParams()    

    const fetchBlogPostDetails = async(blogId) => {
        try {
            const blogCollectionRef = doc(db, "blogposts", blogId)
            const data = await getDoc(blogCollectionRef)
            setBlog(data.data())            
        } catch (error) {
            console.log("error>>", error)            
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchBlogPostDetails(id)
    }, [id]); 

    return (
        <>
        <Helmet>
            <title>{`RopesOut: ${blog.title}`}</title>
            <meta name='description' content={blog.title}/>
            <link rel='canonical' href={`/post/${id}`} />
        </Helmet>
        <div className="bg-white lg:p-8 pb-12 mb-8">
            <div className="relative overflow-hidden shadow-md mb-6">
            {blog?.image && <img src={blog.image} alt="" className="object-top h-full w-full object-cover  shadow-lg rounded-t-lg lg:rounded-lg" />}
            </div>
            <div className="px-4 lg:px-0">
            <div className="md:flex items-center mb-8 w-full">
                <div className="flex items-center md:justify-center mb-2 lg:mb-0 lg:w-auto mr-8">
                {/* <img
                    alt={post.author.name}
                    height="30px"
                    width="30px"
                    className="align-middle rounded-full"
                    src={post.author.photo.url}
                /> */}
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7"
                fill="currentColor"
                style={{color: "#c13584"}}
                viewBox="0 0 24 24">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg> */}
                <p className="inline align-middle text-gray-700 ml-2 font-medium text-lg">
                    <i className="fa-regular fa-user text-sm mx-2 "></i>
                    <Link to={`/${blog?.userid}/pages/profile`} ><span className='hover:underline cursor-pointer text-fuchsia-600' >{blog?.author}</span></Link>
                </p>
                </div>
                <div className="font-medium text-gray-700 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span className="align-middle">{blog.createdat}</span>
                </div>
            </div>
            <h1 className="mb-8 text-3xl font-semibold">{blog.title}</h1>
            <p className="mb-8 whitespace-pre-line font-medium" dangerouslySetInnerHTML={{__html: blog.post}} ></p>
            {/* {post.content.raw.children.map((typeObj, index) => {
                const children = typeObj.children.map((item, itemindex) => getContentFragment(itemindex, item.text, item));

                return getContentFragment(index, children, typeObj, typeObj.type);
            })} */}
            </div>
        </div>
        </>
    
  )
}

export default BlogDetail