import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Blog from './Blog'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBlogs } from '../store/blogs.slice'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '../firebase.config'

function Blogs() {

    const dispatch = useDispatch()
    const blogs = useSelector(state => state.blogs)
    const blogsCollectionRef = collection(db, "blogposts")

    const fetchBlogPosts = async() => {

        const q = query(collection(db, "blogposts"), 
        where("deleted", "==", false),
        where("reviewed", "==", true), 
        orderBy("timestamp", "desc")
        );
        const data = await getDocs(q)  
        // const data = await getDocs(blogsCollectionRef)        
        const blogposts = data.docs.map(doc => ({
            ...doc.data(), 
            id: doc.id
        }))
        dispatch(fetchBlogs(blogposts))                
    }

    useEffect(() => {
        fetchBlogPosts()
    }, [])


    return blogs.map(each => {
        return <Blog source={each} key={each.id} />
    })
}

export default Blogs