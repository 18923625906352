import React from 'react'
import Error404 from '../../components/Error404'

function Page404() {
  return (
    <div className='lg:col-span-12 col-span-1 flex flex-col items-center justify-center md:my-12 lg:my-0 h-full' >
        <Error404 />
    </div>
  )
}

export default Page404