import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

const userSlice = createSlice({
    name: "user", 
    initialState, 
    reducers: {
        fetchUser(state, action){
            return action.payload
            // return [...state, ...action.payload]
            // state.push(action.payload)
        }, 
        updateUser(state, action){
            return {...state, ...action.payload}
        }
    }
})

export const {fetchUser, updateUser} = userSlice.actions;
export default userSlice.reducer;