// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAE_z2E3AMKoyHJPNl5H5RI81dw50dFtm0",
  authDomain: "ropesout.firebaseapp.com",
  projectId: "ropesout",
  storageBucket: "ropesout.appspot.com",
  messagingSenderId: "888705710838",
  appId: "1:888705710838:web:cb525aac98b6b0dcf04ad7",
  measurementId: "G-B0ED07L17Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app)