import React, { useState } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import logotext from '../assets/images/logotext.png'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase.config'
import { useForm } from 'react-hook-form'
import BeatLoader from "react-spinners/BeatLoader";

function Login({flip}) {

    const [loginstatus, setloginstatus] = useState({
        loading: false, 
        error: null,
    })
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm();

    const loginuser = async(data) => {
        try {
            setloginstatus({...loginstatus, loading: true, error: null})
            const user = await signInWithEmailAndPassword(auth, data.email, data.password)
            setloginstatus({...loginstatus, loading: false, error: null})
            return navigate("/");
        } catch (error) {
            console.log("error>>", {error})
            setloginstatus({...loginstatus, loading: false, error: error.code})
        }
    }
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
            {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
                Flowbite    
            </a> */}
            <Link to="/">
                <img src ={logotext} className='h-12 lg:h-16 mb-6'/>
            </Link>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    {/* <i className="fa-solid fa-arrow-left text-lg"></i> */}
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Sign in to your account
                    </h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(loginuser)}>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                            <input {...register('email', {
                                required: {
                                    value: true, 
                                    message: "Please enter email"
                                }, 
                                    pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Please enter valid email"
                                } })}  
                                type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""/>
                            {errors?.email && <p className="text-xs text-red-500">{errors?.email?.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input {...register("password", {
                                required: {
                                    value: true, 
                                    message: "Please enter password"
                                },                                
                            })}
                            type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                        {errors?.password && <p className="text-xs text-red-500">{errors?.password?.message}</p>}    
                        </div>
                        {/* <div className="flex items-center justify-between">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""/>
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                                </div>
                            </div>
                            <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                        </div> */}
                        <button type="submit" className="w-full text-white bg-fuchsia-600 transition duration-500 ease hover:-translate-y-1 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            {
                            loginstatus.loading ? 
                                <BeatLoader
                                    color={"#ffffff"}
                                    loading={true}
                                    // cssOverride={override}
                                    size={6}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> :  
                                "Sign in"
                            } 
                        </button>
                        {loginstatus?.error && <p className="text-xs text-red-500">{loginstatus?.error}</p>}
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Don’t have an account yet? <span className="font-medium text-primary-600 hover:underline hover:text-fuchsia-600 dark:text-primary-500 cursor-pointer" onClick={() => flip(false)} >Sign up</span>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Login