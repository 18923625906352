import { signOut } from 'firebase/auth';
import React, { useState } from 'react'
import { auth } from '../firebase.config';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUser } from '../store/user.slice';

function Avatar() {

    const [isOpen, setOpen] = useState(false);
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const handleDropDown = () => {
        setOpen(!isOpen);
    };
    const navigate = useNavigate()

    window.addEventListener('click', function(e){   
        if (document.getElementById('avatar') && document.getElementById('avatar').contains(e.target)){
          // Clicked in box
        } else{
          // Clicked outside the box
          setOpen(false)
        }
    });

    const logout = async () => {
        dispatch(fetchUser({}))
        await signOut(auth)
        return navigate("/");        
    }

  return (
    <div className='relative transition duration-150 ease-in-out' id='avatar-' >
    {/* <img id="avatarButton" type="button" data-dropdown-toggle="userDropdown" data-dropdown-placement="bottom-start" className="w-10 h-10 rounded-full cursor-pointer" src="/docs/images/people/profile-picture-5.jpg" alt="User dropdown" /> */}
    <i onClick={handleDropDown} id="avatar" data-dropdown-toggle="userDropdown" data-dropdown-placement="bottom-start" type="button" className="fa-regular fa-user w-10 h-10 rounded-full cursor-pointer text-2xl hover:text-fuchsia-700 focus:text-fuchsia-700 flex items-center"></i>
    <div id="userDropdown" className={`z-10 absolute right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 ${
          isOpen ? "block" : "hidden"
        }`}>
        <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
          {/* <div>Bonnie Green</div> */}
          <div className="font-medium truncate">{user?.email}</div>
        </div>
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
          <li>
            <Link to={`${user.uid}/pages/profile`}><span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</span></Link>
          </li>
          <li>
            <Link to={`${user.uid}/pages/create-blog`}><span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Add Blog</span></Link>
          </li>
          {/* <li>
            <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</span>
          </li> */}
        </ul>
        <div className="py-1">
          <span onClick={() => logout()} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</span>
        </div>
    </div>    
    </div>
  )
}

export default Avatar