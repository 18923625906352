import { combineReducers, configureStore } from "@reduxjs/toolkit";
import blogsSlice from "./blogs.slice";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
import userSlice from "./user.slice";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const reducers = combineReducers({
    blogs: blogsSlice, 
    user: userSlice,
})
  
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer, 
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export default store