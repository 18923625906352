import React, { useState } from 'react'
import Login from '../../components/Login'
import Signup from '../../components/signup'

function Signin() {

  const [loginform, setloginform] = useState(true)

  const flip = (data) => {
    setloginform(data)
  }

  return (
    <div className='lg:col-span-12 col-span-1 items-center justify-center lg:my-0 absolute top-0 left-0 z-10 w-screen h-screen transition duration-150 ease-in-out' >
      {
        loginform ?
        <Login flip={data => flip(data)} /> :
        <Signup flip={data => flip(data)} />
      } 
    </div>
  )
}

export default Signin