import './App.css';
import { Link, Redirect, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Blogs from './components/Blogs';
import Recents from './components/Recents';
import Footer from './components/Footer';
import Home from './pages/Home';
import Blogpage from './pages/Blog';
import { useEffect, useState } from 'react';
import Aboutus from './pages/aboutUs/About-us';
import CreateBlog from './pages/Profile/CreateBlog';
import Signin from './pages/Login/Signin';
import Profile from './pages/Profile/Profile';
import 'react-toastify/dist/ReactToastify.css';
import { Notforguests, Notforusers } from './components/Protectedroutes';
import { useSelector } from 'react-redux';
import Page404 from './pages/Notfound/404';
// import Privacy from './pages/policy/privacy'

function App() {

  const [isLoggedIn, setisLoggedIn] = useState(false)
  const user = useSelector(state => state.user)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    user?.uid ? setisLoggedIn(true) : setisLoggedIn(false)
  }, [user]);

  return (    
    <>
      <Header />
      <div className="App container mx-auto px-10 mb-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
            <Routes>
              <Route path='*' element={<Page404 />} />
              <Route exact path='/' element={<Home />} />
              {/* <Route exact path='/privacy-policy' element={<Privacy />} /> */}
              <Route path='/login' 
                element= {
                  <Notforusers isLoggedIn={isLoggedIn}>
                    <Signin />
                  </Notforusers>
                }  
              />
              <Route exact path='/post/:id' element={<Blogpage />} />
              <Route exact path='/pages/about-us' element={<Aboutus />} />              
              <Route path='/:userid/pages/create-blog' 
                element= {
                  <Notforguests isLoggedIn={isLoggedIn}>
                    <CreateBlog />
                  </Notforguests>
                } 
              />
              <Route exact path='/:userid/pages/profile' element={<Profile />} />
            </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
